import { Link, Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import Landing from './pages';
import Privacy from './pages/privacy';
import { Button } from 'antd';

const Layout = () => {

  const onScrollTo = (id: any) => () => {
    const el = document.getElementById(id);
    console.log('Scrolling to', id, el)
    el?.scrollIntoView({ behavior: 'smooth' });
  }

  return <>
    <div className="app">
      <div style={{ backgroundColor: '#001529', boxShadow: '0 5px 8px rgba(0,0,0,.15)' }}>
        <div className="container nav-container">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src="/logo.jpg" width={25} style={{ borderRadius: 5, marginRight: 5 }} />
            <p>
              <a style={{ fontSize: 24, color: 'white', textDecoration: 'none' }} href="/">  Stable Diffusion <span style={{ color: '#562BECff', paddingRight: 5 }}>XL</span></a>
            </p>
          </div>
          <div className="nav">
            <Link to="/#about" onClick={onScrollTo("about")}>About</Link>
            <Link to="/#features" onClick={onScrollTo("features")}>Features</Link>
            <Link to="/#playground" onClick={onScrollTo("playground")} >Playground</Link>
            <Link to="/#faq" onClick={onScrollTo("faq")} >FAQs</Link>
          </div>
          <div >
            <Link to="https://huggingface.co/stabilityai/stable-diffusion-xl-base-1.0" target='_blank'>
              <Button type="primary">Download Model</Button>
            </Link>
          </div>
        </div>
      </div>
      <Outlet />
    </div >
    <footer style={{ backgroundColor: '#001529', boxShadow: '0 5px 8px rgba(0,0,0,.15)', color: 'white' }}>
      <div className='container' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p>
          Copyright © 2023 Stable Diffusion XL Online. All rights reserved. By <a target="_blank" href="https://techbridgelabs.com">TechBridge Labs, LLC</a>
        </p>
        <div>
          <a href="/privacy" target='_blank'>Privacy Policy</a>
        </div>
      </div>

    </footer>
  </>
}
const routes = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <Landing />
      },
      {
        path: '/privacy',
        element: <Privacy />
      }
    ]
  },
])
function App() {
  return (
    <RouterProvider router={routes} />
  );
}

export default App;
