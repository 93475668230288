import { Collapse, Typography } from "antd"

const questions = [
    {
        "question": "What is a stable diffusion AI model?",
        "answer": "A stable diffusion AI model is a powerful tool for creators. It takes text descriptions as input and generates high-quality images, making it a valuable asset creation tool for various creative projects."
    },
    {
        "question": "How can I use a stable diffusion AI model to generate assets like images and illustrations?",
        "answer": "To create assets using a stable diffusion AI model, you provide a text prompt that describes the asset you want to generate. The AI model then interprets the text and generates an image or illustration that matches your description."
    },
    {
        "question": "What are the advantages of using a stable diffusion AI model for asset creation?",
        "answer": "Using a stable diffusion AI model offers several advantages, including the ability to quickly generate custom images, access to a wide range of creative styles, and the potential to save time and effort in asset creation."
    },
    {
        "question": "Can I customize the style and appearance of assets generated by a stable diffusion AI model?",
        "answer": "Yes, many stable diffusion AI models allow you to control the style, colors, and other parameters of the generated assets. This enables you to tailor the output to match your creative vision."
    },
    {
        "question": "What types of assets can I create with a stable diffusion AI model?",
        "answer": "You can create various assets, including illustrations, concept art, character designs, product visuals, and more. The versatility of these models makes them suitable for a wide range of creative projects."
    },
    {
        "question": "Are there any copyright or licensing considerations when using AI-generated assets?",
        "answer": "Yes, it's important to be aware of copyright and licensing issues when using AI-generated assets. Ensure that you have the right to use the generated content for your intended purposes and respect the intellectual property of others."
    },
    {
        "question": "Is it possible to fine-tune a stable diffusion AI model to generate assets specific to my project?",
        "answer": "Yes, you can fine-tune AI models with your own data to create assets that align with your project's requirements. Fine-tuning allows for greater customization and control over the generated assets."
    },
    {
        "question": "What are the ethical considerations when using AI-generated assets in creative projects?",
        "answer": "Ethical considerations include ensuring that AI-generated assets do not promote harmful content, respect diversity and inclusion, and adhere to ethical guidelines in your creative work."
    },
]

const items = questions.map(question => ({
    key: question.question,
    label: question.question,
    children: <Typography.Paragraph>{question.answer}</Typography.Paragraph>
}))

const FAQs = () => {
    return <Collapse items={items} defaultActiveKey={['1']} />;

}

export default FAQs