import {
  Button,
  Col,
  Form,
  Grid,
  Input,
  InputNumber,
  Row,
  Select,
  Slider,
} from "antd";
import { SCHEDULER_OPTIONS } from "../../constants/constants";
import { useEffect, useState } from "react";
import axios from "axios";

const initialValues = {
  prompt: "a happy dog playing in a garden",
  negative_prompt: "bad, ugly, disfigured, deformed",
  width: 768,
  height: 768,
  scheduler: "K_EULER",
  num_inference_steps: 30,
  guidance_scale: 7.5,
  refine: "expert_ensemble_refiner",
};

const SHAPES = [
  {
    name: "1:1",
    width: 1024,
    height: 1024,
  },
  {
    name: "16:9",
    width: 1024,
    height: 576,
  },
  {
    name: "9:16",
    width: 576,
    height: 1024,
  },
];

const initialGeneration = {
  id: "c498bdb5-9ea7-4237-a082-331e2fbf28a9",
  prompt: "a happy dog playing in a garden",
  negative_prompt: "bad, ugly",
  width: 768,
  height: 768,
  num_outputs: 1,
  scheduler: "K_EULER",
  num_inference_steps: 50,
  guidance_scale: 7.5,
  seed: null,
  refine: "expert_ensemble_refiner",
  high_noise_frac: 0.8,
  refine_steps: null,
  error: "",
  status: "COMPLETED",
  createdAt: 1693086872852,
  variations: [
    {
      id: "0_1693086880755",
      url: "https://s3.sdxl.techblazelabs.com/generations/0_1693086880755.png",
    },
  ],
};

const GenerationForm = ({ styles }: { styles: any[] }) => {
  const [form] = Form.useForm();
  const [generation, setGeneration] = useState<any>(initialGeneration);
  const [selectedStyle, setSelectedStyle] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values: any) => {
    setLoading(true);
    setGeneration(null);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/generations`,
        { ...values, style: selectedStyle }
      );
      const data = response.data;
      setGeneration(data.generation);
      loadGeneration(data.generation);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const loadGeneration = async (loadedGeneration: any) => {
    setTimeout(async () => {
      if (
        loadedGeneration &&
        ["QUEUED", "PROCESSING"].includes(loadedGeneration?.status)
      ) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/generations/${loadedGeneration?.id}`
          );
          const data = response.data;
          setGeneration(data);
          loadGeneration(data);
        } catch (err) {}
      } else {
        setLoading(false);
      }
    }, 5000);
  };

  useEffect(() => {
    if (styles && styles.length) {
      setSelectedStyle(styles[0].name);
    }
  }, [styles]);

  return (
    <div>
      <Form
        form={form}
        onFinish={onSubmit}
        layout="vertical"
        style={{ border: "1px solid #001529", padding: 20, borderRadius: 5 }}
        initialValues={initialValues}
      >
        <Row gutter={24}>
          <Col xs={24} md={12} style={{ marginBottom: 20 }}>
            <Form.Item
              name="prompt"
              label="Prompt"
              rules={[{ required: true }]}
              tooltip="Type in a full descriptive sentence, as if you were writing a caption for a photo. Include as much detail as you see fit, including colors, styles, and emotions."
            >
              <Input.TextArea rows={5} />
            </Form.Item>
            <Form.Item name="negative_prompt" label="Negative Prompt">
              <Input.TextArea rows={5} />
            </Form.Item>
            <Row style={{ marginBottom: 20 }}>
              <Col span={24}>
                <p>Style</p>
                <div
                  style={{
                    maxWidth: "100%",
                    overflowX: "auto",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {styles.map((style: any) => (
                    <div
                      key={style.name}
                      style={{
                        textAlign: "center",
                        marginRight: 10,
                        cursor: "pointer",
                        border: `2px solid ${
                          selectedStyle === style.name ? "#001529" : "#e5e7eb"
                        }`,
                        borderRadius: 10,
                      }}
                      onClick={() => setSelectedStyle(style.name)}
                    >
                      <img src={style.url} width={100} />
                      <p style={{ margin: 0 }}>{style.name}</p>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="width"
                  label="Width"
                  tooltip="Multiple by 8"
                  rules={[{ required: true }]}
                  style={{ marginBottom: 5 }}
                >
                  <InputNumber
                    step={8}
                    max={1024}
                    min={512}
                    style={{ width: "100%", marginBottom: 0 }}
                  />
                </Form.Item>
                {/* <Slider defaultValue={initialValues.width} max={1024} onChange={(val) => { console.log("CHANGED", val); form.setFieldValue('width', val) }} min={512} step={128} /> */}
              </Col>
              <Col span={12}>
                <Form.Item
                  name="height"
                  label="Height"
                  tooltip="Multiple by 8"
                  rules={[{ required: true }]}
                  style={{ marginBottom: 0, paddingBottom: 0 }}
                >
                  <InputNumber
                    step={8}
                    max={1024}
                    min={512}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                {/* <Slider defaultValue={initialValues.height} max={1024} onChange={(val) => form.setFieldValue('height', val)} min={512} step={128} /> */}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div style={{ marginBottom: 22 }}>
                  {SHAPES.map((shape) => (
                    <span
                      style={{
                        display: "inline-block",
                        cursor: "pointer",
                        padding: 5,
                        border: "1px solid #1677ff",
                        marginRight: 5,
                        borderRadius: 5,
                      }}
                      onClick={() =>
                        form.setFieldsValue({
                          width: shape.width,
                          height: shape.height,
                        })
                      }
                    >
                      {shape.name}
                    </span>
                  ))}
                </div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="num_inference_steps"
                  label="Steps"
                  rules={[{ required: true }]}
                >
                  <InputNumber min={10} max={100} style={{ width: "100%" }} />
                </Form.Item>
                {/* <Slider defaultValue={initialValues.num_inference_steps} max={100} onChange={(val) => { form.setFieldValue('num_inference_steps', val) }} min={10} step={10} /> */}
              </Col>
              <Col span={12}>
                <Form.Item
                  name="guidance_scale"
                  label="CFG Scale"
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    min={1}
                    max={20}
                    step={0.5}
                    precision={1}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                {/* <Slider defaultValue={initialValues.guidance_scale} max={20} onChange={(val) => { form.setFieldValue('guidance_scale', val) }} min={1} step={0.5} /> */}
              </Col>
            </Row>
            <Form.Item
              name="scheduler"
              label="Scheduler"
              rules={[{ required: true }]}
            >
              <Select>
                {SCHEDULER_OPTIONS.map((option) => (
                  <Select.Option key={option} value={option}>
                    {option}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="seed" label="Seed">
              <InputNumber
                min={1}
                max={1000000000}
                step={100}
                style={{ width: "100%" }}
              />
            </Form.Item>
            {generation?.error ? (
              <p style={{ color: "red" }}>{generation.error}</p>
            ) : null}
            <div style={{ textAlign: "right" }}>
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                block
                style={{ height: 50 }}
              >
                Generate
              </Button>
            </div>
          </Col>
          <Col xs={24} md={12} style={{ position: "relative" }}>
            {generation?.variations?.map((image: any) => (
              <div key={image.url} style={{ textAlign: "center" }}>
                <img
                  src={image.url}
                  width={"100%"}
                  style={{ maxWidth: "100%" }}
                />
                <a
                  href={`${image.url}`}
                  download={image.url.split("/").pop()}
                  target="_blank"
                >
                  <Button type="link">Download</Button>
                </a>
              </div>
            ))}
            <div>
              <h3>
                Are you a content creator? <br />
                Try for free:
              </h3>
              <Row>
                <Col span={12}>
                  <a
                    href="https://instaclipify.ai"
                    target="_blank"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#6165db",
                    }}
                  >
                    <img
                      style={{ width: 50, height: 50, marginRight: 10 }}
                      src="https://instaclipify.ai/logo.png"
                    />
                    InstaClipify.AI
                  </a>
                </Col>
                <Col span={12}>
                  <a
                    href="https://streamingbots.com"
                    target="_blank"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#6165db",
                    }}
                  >
                    <img
                      style={{ width: 50, height: 50, marginRight: 10 }}
                      src="https://app.streamingbots.com/logo_new_smiling.png"
                    />
                    StreamingBots.com
                  </a>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default GenerationForm;
