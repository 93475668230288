import { useEffect } from "react"

const GoogleAds = () => {
    // useEffect(() => {

    // alert('Showing ads')
    // }, [])

    return (
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1251413315817820"
        crossOrigin="anonymous"></script>
    )
}

export default GoogleAds