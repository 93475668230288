export const SCHEDULER_OPTIONS = [
    'DDIM',
    'DPMSolverMultistep',
    'HeunDiscrete',
    'KarrasDPM',
    'K_EULER_ANCESTRAL',
    'K_EULER',
    'PNDM',
]

export const REFINE_OPTIONS = [
    'no_refiner',
    'expert_ensemble_refiner',
    'base_image_refiner',
]
