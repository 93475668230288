import { Carousel, Col, Row, Typography } from "antd"
import GenerationForm from "../components/GenerationForm/GenerationForm"
import GoogleAds from "../components/GoogleAds/GoogleAds"
import FAQs from "../components/FAQs/FAQs"
import { useEffect, useState } from "react"
import axios from "axios"

const Landing = () => {
    const [styles, setStyles] = useState([]);

    useEffect(() => {
        async function loadStyles() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/generations/settings`);
                const data = response.data;
                setStyles(data.stylePresets)
            } catch (err) {
                console.log(err);
            }
        }
        loadStyles();
    }, [])

    return (
        <div >
            <GoogleAds />
            <div style={{ width: '100%', height: '100vh', backgroundImage: 'url(/assets/robot-2301646_1920.jpg)', backgroundSize: 'cover', backgroundAttachment: 'fixed', backgroundPosition: 'center', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <div className="container">
                    <div style={{ textAlign: 'center' }}>
                        <h1 style={{ fontSize: 50, marginTop: 0, background: 'rgba(0,0,0,0.9)', display: 'inline-block', padding: 10, borderRadius: 10 }}>
                            Stable Diffusion XL <span style={{ color: '#562BECff' }}>Online</span>
                        </h1>
                        <h2 style={{ background: 'rgba(0,0,0,0.9)', display: 'inline-block', padding: 10, borderRadius: 10 }}>
                            State-Of-The-Art Photo-Realistic Image Generation from Text
                        </h2>
                    </div>
                </div>
            </div>
            <div className="container">
                <section style={{ marginBottom: 100 }} >
                    <Typography.Title level={2} style={{ textAlign: 'center', fontSize: 40 }} id="about">About</Typography.Title>
                    <Row gutter={24}>
                        <Col xs={24} md={10}>
                            <div style={{ fontSize: 18, flex: 4, paddingRight: 20 }}>
                                <p style={{ paddingTop: 0, marginTop: 0 }}>
                                    Stable Diffusion XL unveils a latent text-to-image diffusion model with the prowess to produce lifelike visuals from diverse textual inputs
                                </p>
                                <p>
                                    It fosters autonomous creative expression, resulting in awe-inspiring imagery, and democratizes artistic endeavors by enabling billions to craft breathtaking art in mere seconds.
                                </p>
                                <p>
                                    Experience the power of Stable Diffusion XL as you effortlessly create captivating art with text to image through our platform, completely free of charge.
                                </p>
                                <p>
                                    Create your own stock images for free! For both Personal and Commercial use. Just describe what you need and let the AI turn your imagination into a stock image for your personal and commercial projects
                                </p>
                            </div>
                        </Col>
                        <Col xs={24} md={14}>
                            <img src="https://s3.sdxl.techblazelabs.com/generations/0_1693886503337.png" width="100%" style={{ borderRadius: 10 }} />
                        </Col>
                    </Row>
                </section>
                <section style={{ marginBottom: 100 }} >
                    <Typography.Title level={2} style={{ textAlign: 'center', fontSize: 40 }} id="features">Features</Typography.Title>
                    <Row gutter={24}>
                        <Col xs={24} md={10}>
                            <Carousel
                                effect="fade"
                                autoplay
                            >
                                {styles.filter((item: any) => !!item.url).map((style: any) => (
                                    <div style={{ position: 'relative' }}>
                                        <img src={style.url} style={{ width: '100%' }} />
                                        <Typography.Title style={{ textAlign: 'center', position: 'absolute', bottom: 20, left: 0, right: 0, backgroundColor: 'white' }} level={3}>{style.name}</Typography.Title>
                                    </div>
                                ))}
                            </Carousel>
                        </Col>
                        <Col xs={24} md={14}>
                            <Typography.Title level={3} style={{ marginTop: 0 }}>Easy to use</Typography.Title>
                            <p>
                                stablediffusionxlonline.com is an easy-to-use interface for creating images using the latest released Stable Diffusion XL generation model.
                            </p>
                            <Typography.Title level={4}>
                                Use Stable Diffusion XL Online to create
                            </Typography.Title>
                            <ul style={{ fontSize: 20 }}>
                                <li>
                                    Assets for social media
                                </li>
                                <li>
                                    Assets for Games
                                </li>
                                <li>
                                    Assets for Designs
                                </li>
                                <li>
                                    Pixel Art, Anime, Fantasy, Comic Book and much more styles
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </section>


                <Typography.Title level={2} style={{ textAlign: 'center', fontSize: 40 }} id="playground">
                    Playground
                </Typography.Title>
                <GenerationForm styles={styles} />
                <section style={{ marginBottom: 50 }} >
                    <Typography.Title level={2} style={{ textAlign: 'center', fontSize: 40 }} id="faq">FAQs</Typography.Title>
                    <FAQs />
                </section>
            </div>
            {/* <section>
                <p>DISCLAIMER: This website is an experiment with no guarantees. Use at your own discretion</p>
            </section> */}
        </div>
    )

}
export default Landing