const Privacy = () => {

    return <div style={{ width: '80%', margin: '20px auto' }}>
        <h1 style={{ textAlign: 'center' }}>Privacy Policy</h1>
        <p>
            TechBridge Labs, LLC ("us", "we", or "our") operates stablediffusionxlonline.com (the "Site"). This page informs you of our policies regarding the collection, use, and disclosure of Personal Information and data generated through the use of our text-to-image generation service.
        </p>
        <h2>
            Data Collection and Use
        </h2>
        <p>
            We do not collect personal user information unless explicitly provided by the user. However, when you generate text-to-image content using our service, the generated data is stored on our servers. This generated data may include textual input, image output, and associated metadata.
        </p>
        <h2>
            Data Storage
        </h2>
        <p>
            The generated text-to-image data is stored on our secure servers for analysis and improvement of our services. We retain this data for a reasonable period to perform analytics, enhance user experience, and optimize our platform's functionality.
        </p>
        <h2>

            Data Deletion
        </h2>
        <p>
            We may delete the generated text-to-image data when it is no longer needed for analysis or when we determine that retaining it is no longer necessary. This deletion will occur in accordance with our internal policies and legal requirements.
        </p>
        <h2>
            Data Security
        </h2>
        <p>
            We take reasonable measures to protect the security of the generated data. However, it's important to note that no method of transmission over the Internet or electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your data, we cannot guarantee its absolute security.
        </p>
        <h2>
            Analytics and Improvement
        </h2>
        <p>
            We may use the generated data for analytical purposes to improve our services, enhance user experience, and develop new features. This may involve reviewing patterns, trends, and usage behavior, all of which will be done in an aggregated and anonymized manner.
        </p>
        <h2>

            Updates to this Privacy Policy
        </h2>

        <p>
            We reserve the right to update or change our Privacy Policy at any time. When we do, we will revise the "last updated" date at the top of this page. We encourage users to frequently check this page for any changes.
        </p>
        <h2>
            Contact Us
        </h2>
        <p>
            If you have any questions about this Privacy Policy, please contact us at info@techbridgelabs.com.
        </p>
    </div>
}

export default Privacy;